import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import { TrackballControls } from "three/addons/controls/TrackballControls.js";


const OBJViewer = ({ OBJFile }) => {
  // CREATING REFERENCE TO THE DIV WHERE WE WILL SHOW OUR CANVAS
  const containerRef = useRef(null);

  const [modelLoaded, setModelLoaded] = useState(false);

  useEffect(() => {
    // -------------- SCENE SETUP -------------- vv
    const scene = new THREE.Scene();
    //
    // ADDS MAIN XYZ(RGB) AXES TO SCENE
    // scene.add(new THREE.AxesHelper(5));
    //
    // ------ CREATES CAMERA THAT WE WILL SEE THE SCENE THROUGH
    const camera = new THREE.PerspectiveCamera(25, 1, 0.1, 2000);
    // ------ SETS POSITION OF CAMERA(DEFAULT IS (0,0,0))
    camera.position.set(0, 0, -300);
    // ------ THIS CODE IS ALSO ACCOMPLISHING THE SAME AS THE CODE ABOVE
    //
    //
    // ------ CREATE AN AMBIENT LIGHT
    const ambLight = new THREE.AmbientLight(0x404040);
    // ------ ADD THE LIGHT TO THE SCENE
    scene.add(ambLight);
    // ------ CREATE A DIRECTIONAL LIGHT
    let light = new THREE.DirectionalLight(0xffffff, 0.5);
    light.position.set(200, 400, -200);
    // ------ ADD THE LIGHT TO THE SCENE
    camera.add(light);
    scene.add(camera);
    // scene.add(light);
    // ------ THIS IS THE ANNOYING LINE AND LIGHT PLANE
    // let helper = new THREE.DirectionalLightHelper(light);
    // scene.add(helper);
    // -------------- SCENE SETUP -------------- ^^
    //
    //
    //
    //
    // -------------- CREATE ORBIT CONTROLS (to navigate the scene) -------------- vv
    const controls = new OrbitControls(camera, containerRef.current);
    // MAKES THE PHYSICS HEAVIER/NICER FOR CONTROLS
    controls.enableDamping = true;
    // MAKES MODEL AUTO-ROTATE
    controls.autoRotate = true;
    // SETS THE ROTATION SPEED
    controls.autoRotateSpeed = 4;
    // SETTING TO FALSE MAKES IT SO USER CAN'T PAN
    controls.enablePan = false;
    // SETTING TO FALSE MAKES IT SO USER CAN'T ZOOM
    controls.enableZoom = false;
    controls.rotateSpeed = 2.8;
    controls.dampingFactor = 0.02;
    // -------------- CREATE ORBIT CONTROLS (to navigate the scene) -------------- ^^
    //
    //
    //
    //
    // -------------------- RENDERER SETUP -------------------- vv
    // ------ FUNCTION TO CALCULATE RENDER SIZE DEPENDING ON THE WIDTH OF OUR WINDOW(CHECKS IF WE ARE ON MOBILE OR NOT)
    // const renderSize = () => {
    //   // if (window.innerWidth < 500) {
    //   //   return 240;
    //   // }
    //   return 360;
    // };
    // ------ CREATES A NEW INSTANCE OF A RENDERER
    // const renderer = new THREE.WebGLRenderer();
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    // ------ SETS THE SIZE OF THE RENDERER TO THE VALUE OUR renderSize FUNCTION RETURNS
    // renderer.setSize(renderSize(), renderSize());
    // -------------------- RENDERER SETUP -------------------- ^^
    //
    //
    //
    //
    // renderer HAS PROPERTY domElement. THIS HOLDS OUR CANVAS WITH OUR MODEL. WE ARE ATTACHING THAT TO THE DIV WHERE WE WANT TO SHOW IT BELOW IN OUR COMPONENT RETURN.
    containerRef.current.appendChild(renderer.domElement);
    //
    //
    //
    window.addEventListener("resize", resizeCanvasToDisplaySize);
    //
    // -------------------- OBJECT LOADER SETUP -------------------- vv
    // ------ CREATES LOADER
    const objLoader = new OBJLoader();
    // ------ LOADS THE OBJ FILE
    objLoader.load(OBJFile, (model) => {
      // once the obj is loaded, add it to the scene
      scene.add(model);
      // once the obj is added to the scene, render the scene
      renderer.render(scene, camera);
      setModelLoaded(true);
    });
    // -------------------- OBJECT LOADER SETUP -------------------- ^^
    //
    //
    function resizeCanvasToDisplaySize() {
      const canvas = renderer.domElement;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;

      // you must pass false here or three.js sadly fights the browser
      renderer.setSize(width, height, false);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      // set render target sizes here
    }
    //
    //
    //
    // -------------------- ANIMATE FUNCTION -------------------- vv
    const animate = () => {
      // ------ REQUEST THE ANIMATION
      requestAnimationFrame(animate);
      // ------ UPDATE THE CONTROLS
      controls.update();
      // ------ THIS IS IF WE ARE USING THE LIGHT HELPER
      // helper.update();
      // ------ RENDER THE SCENE
      renderer.render(scene, camera);
    };

    const resizeObserver = new ResizeObserver(resizeCanvasToDisplaySize);
    resizeObserver.observe(renderer.domElement, { box: "content-box" });

    // CALL THE ANIMATE FUNCTION
    animate();
    // -------------------- ANIMATE FUNCTION -------------------- ^^
    //
    //
    //
    //
    return () => {
      // containerRef.current.removeChild(renderer.domElement);
    };
  }, [OBJFile]);

  return (
    <div id="model-container">
      <div
        style={{ display: modelLoaded ? "block" : "none" }}
        ref={containerRef}
      ></div>
      <div
        id="model-loading"
        style={{ display: !modelLoaded ? "flex" : "none", width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black', color: 'white'}}
      >
        LOADING...
      </div>
    </div>
  );
};

export default OBJViewer;
