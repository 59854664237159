import './App.css';
import OBJViewer from './OBJviewer/OBJviewer.js';
import kwan_model from './kwan_model.obj'

function App() {
  return (
    <div className="App">
      <OBJViewer OBJFile={kwan_model}/>
    </div>
  );
}

export default App;
